
import { reactive, toRefs } from 'vue'
import { opertorModify, isValid, operatorList } from '../../utils/api'
import { geneIndex, showMessage } from '../../utils/util'
export default {
    name: '',
    setup() {
         const state= reactive({
             buttons: [
                 {text: '编辑', eventName: 'edit'},
             ],
             title: '详情',
             confirmLoading: false,
             showDialog: false,
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: 'ID', dataIndex: 'id', key: 'id'},
                 {title: '姓名', dataIndex: 'name', key: 'name'},
                 {title: '电话', dataIndex: 'phone', key: 'phone'},
                 {title: '状态', dataIndex: 'statusText', key: 'statusText'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                 {type: 'input', label: '配送员ID', value: undefined},
                 {type: 'input', label: '姓名', value: undefined},
                 {type: 'input', label: '电话', value: undefined},
                 {type: 'selector', label: '状态', value: undefined, options: ['初始', '正常', '冻结', '注销']},
                 {type: 'button', label: '搜索', eventName: 'search'}
             ],
             popForms: [
                 {label: 'ID', value: '', type: 'input', name: 'id'},
                 {label: '姓名', value: '', type: 'input', name: 'name'},
                 {label: '电话', value: '', type: 'input', name: 'phone'},
                 {label: '状态', value: '', type: 'radio', options: [{label: '初始', value: '0'}, {label: '正常', value: '10'}, {label: '冻结',value: '40'}, {label: '注销', value: '99'}],}
                ],
             forms: {name: '', id: '', phone: '', status: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             loading: false,
             status: 0,
        })
        function changeStatus(status: any) {
            if (isNaN(Number.parseInt(status))) {
                return status == '初始' ? 0
                        : status == '正常' ? 10
                        : status == '冻结' ? 40 
                        : status == '注销' ? 99 : undefined
            } else {
                return status == 0 ? '初始'
                        : status == 10 ? '正常'
                        : status == 40 ? '冻结'
                        : status == 99 ? '注销' : undefined
            }
        }
        function edit(event: any) {
            const forms = state.popForms
            forms[0].value = event.id 
            forms[1].value = event.name 
            forms[2].value = event.phone
            forms[3].value = event.status
            state.showDialog = true
        }
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const forms = state.formItems
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    id: forms[0].value,
                    name: forms[1].value,
                    phone: forms[2].value,
                    status: changeStatus(forms[3].value)
                }
                const result = await operatorList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = geneIndex(result.data.records, state.pageSize, state.currentPage)
                    state.tableList.forEach((ele: any) => {
                        ele.statusText = changeStatus(ele.status)
                    })
                }
                if (isValid(result)) {
                    console.log(result)
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }   
        loadData()
          // 修改运营人员信息
        async function modifyInfo() {
            try {
                const forms = state.popForms
                const params = {
                    id: forms[0].value,
                    name: forms[1].value,
                    phone: forms[2].value,
                    status: forms[3].value
                }
                const result = await opertorModify(params)
                if (result.code == 0) {
                    showMessage('修改成功')
                    loadData()
                    state.showDialog = false
                }
            } catch(e) {
                console.error(e)
            }
        }
        return {
            ...toRefs(state),
            loadData,
            edit,
            modifyInfo
        }
    },
}
